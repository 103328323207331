import React from 'react';

const WhatWeOfferSection = () => {
  return (
    <section id="Offer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/offer_dsk.jpg"
                alt="Middle-aged couple getting insurance and financial advice from their rep"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/offer_mbl.jpg"
                alt="Middle-aged couple getting insurance and financial advice from their rep"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">What We Offer</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4610/images/offer1.jpg"
                alt="Middle aged African American couple dancing in the backyard"
                width="400"
              />
            </div>

            <p>
              We offer a variety of financial services products at competitive prices.
            </p>

            <p>Look to WoodmenLife for:</p>

            <div style={{ margin: '5px 0 0 15px' }}>
              <b className="Oswald">&bull;</b>&nbsp; <b>Life Insurance</b>
              <br />
              <b className="Oswald">&bull;</b>&nbsp; <b>Annuities</b>
              <br />
              <b className="Oswald">&bull;</b>&nbsp; <b>Investments</b>
              <span className="size12">
                <sup>9</sup>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ marginTop: '30px' }} className="size10">
            9. Securities are offered through Woodmen Financial Services, Inc. (WFS), 1700 Farnam Street, Omaha, NE 68102, 877-664-3332, member FINRA/SIPC, a wholly owned subsidiary of Woodmen of the World Life Insurance Society (collectively “WoodmenLife”). Securities other than the WoodmenLife Variable Annuity are issued by companies that are not affiliated with WoodmenLife. This material is intended for general use with the public. WFS is not providing investment advice for any individual or any individual situation, and you should not look to this material for any investment advice. WFS has financial interests that are served by the sale of these products or services.
            </div>

          
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Plan"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default WhatWeOfferSection;
