import React from 'react';

interface NavSectionProps {
  hasRep?: boolean;
}

const NavSection = ({ hasRep }: NavSectionProps) => {
  return (
    <nav className="navbar navbar-custom fixed-top" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">
          <img
            src="/brochure/4610/images/logo.png"
            style={{ width: '200px' }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto" style={{ textAlign: 'center' }}>
          <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Why">
                Why We're Here
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Story">
                The WoodmenLife
                <span style={{ fontSize: '12px' }} className="RegMark">
                  <sup>&reg;</sup>
                </span>{' '}
                Story
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Security">
                Financial Security
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Extras">
                WoodmenLife Extras
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Work">
                How We Work With You
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Offer">
                What We Offer
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Plan">
                Creating a Plan for You
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#Enough">
                How Much is Enough
              </a>
            </li>
            {hasRep && (
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#Connect">
                  Connect with WoodmenLife
                </a>
              </li>
            )}
            <li className="nav-item">&nbsp;</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavSection;
