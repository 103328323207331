import React from 'react';

const HowWorkSection = () => {
  return (
    <section id="Work">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/work_dsk.jpg"
                alt="African American mom and her two daughers going for a walk in the park"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/work_mbl.jpg"
                alt="African American mom and her two daughers going for a walk in the park"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">How We Work With You</h1>
            <p>
            We want to help you reach your financial dreams. WoodmenLife will work with you to protect your family&#8217;s financial security and plan for the future. Our goal is to help you discover what you want, pinpoint what you need and determine what you can reasonably afford.
            </p>

            <p>
            At WoodmenLife, we realize that everyone has their own unique financial needs. Wherever you are in life, the ultimate goal is to strengthen your financial portfolio. Whether you're interested in Asset Management, Retirement Income Management or Estate Management, Risk Management remains a constant, helping to keep your money safe. As you grow older and experience different life events, your needs and priorities will change. WoodmenLife will be there to help you see what&#8217;s possible, no matter where you are in life. Let&#8217;s talk about where you are and what your specific needs may be.
            </p>

            <div className="text-center hideLg">
              <img
                src="/brochure/4610/images/work_graphic.svg"
                className="img-responsive"
                style={{ margin: '20px 0 40px 0' }}
                alt=""
              />
            </div>
            <div className="text-center hideSm">
              <img
                src="/brochure/4610/images/work_graphic.svg"
                style={{ margin: '20px 0 40px 0', width: '750px' }}
                alt=""
              />
            </div>

            <div style={{ margin: '0 0 20px 0' }}><h2 className="Oswald WLblue"><i>What Is Your Unique Financial Need?</i></h2></div>

            <div className="text-center hideLg">
              <img
                src="/brochure/4610/images/work_finNeed_graphic.png"
                className="img-responsive"
                style={{ margin: '0 0 40px 0' }}
                alt=""
              />
            </div>
            <div className="text-center hideSm">
              <img
                src="/brochure/4610/images/work_finNeed_graphic.png"
                style={{ margin: '0 0 40px 0', width: '750px' }}
                alt=""
              />
            </div>



          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-lg-10 mx-auto">
            
                 
              <div className="royalBkg">
                <b className="Oswald white size20">Estate Management</b>
              </div>
              <div className="royalLiteBkg" style={{ margin: '0 0 20px 0' }}>
                  Estate Management occurs when you're making plans to transfer your estate after you're gone, with the least amount of tax consequences. Consult with your attorney and tax advisor for specific estate planning, legal or tax advice.
                  <div className="size10">WoodmenLife Representatives are not authorized to offer tax advice. For tax advice, consult your professional tax advisor.</div>
                  <div style={{ textAlign: 'right' }}><b className="powder Oswald size20">LEAVE A LEGACY</b></div>  
              </div>


              <div className="midnightBkg">
              <b className="Oswald white size20">Retirement Income Management</b>
              </div>
              <div className="midnightLiteBkg" style={{ margin: '0 0 20px 0' }}>
                  If you've saved and managed your money through your working years, you&#8217;ll want to take the steps to safeguard your assets and develop a plan that involves creating income to use during your retirement years.
                  <div style={{ textAlign: 'right' }}><b className="powder Oswald size20">DISTRIBUTION</b></div>  
              </div>


              <div className="greenBkg">
              <b className="Oswald white size20">Asset Management</b>
              </div>
              <div className="greenLiteBkg" style={{ margin: '0 0 20px 0' }}>
                  In the Asset Management phase, you begin planning for your future necessities, hopes and dreams. This could include buying a home, providing for your children&#8217;s college education or preparing for a rainy day.
                  <div style={{ textAlign: 'right' }}><b className="powder Oswald size20">ACCUMULATION</b></div>  
              </div>
              
              
              <div className="powderBkg">
              <b className="Oswald white size20">Risk Management</b>
              </div>
              <div className="powderLiteBkg">
                Insurance can help protect you and your family against the financial loss that could result from a sickness or death<span className="size12"><sup>8</sup></span>, including lost income and increased expenses. Risk Management insurance protection can help to keep your money safe and ensures a healthy financial future.
                  <div style={{ textAlign: 'right' }}><b className="powder Oswald size20">INSURANCE PROTECTION</b></div>  
              </div> 

                <p style={{ margin: '20px 0 0 0' }} className="size10">
                  8. Some products are offered through Woodmen Insurance Agency, Inc., a wholly owned subsidiary of Woodmen of the World Life Insurance Society (WoodmenLife). WIA products are issued by carriers not affiliated with WoodmenLife. All products may not be available in all states. Not all Representatives are licensed to sell all products. Purchasers of products offered through Woodmen Insurance Agency, Inc. do not become members of WoodmenLife and are not eligible for WoodmenLife Extras.
                </p>
                


        </div>
      </div>
    </div>



      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Offer"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default HowWorkSection;
