import React from 'react';

interface HowMuchSectionProps {
  hasRep?: boolean;
}

const HowMuchSection = ({ hasRep }: HowMuchSectionProps) => {
  return (
    <section id="Enough">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/enough_dsk.jpg"
                alt="Three kids running along a hiking trail"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/enough_mbl.jpg"
                alt="Three kids running along a hiking trail"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">How Much is Enough?</h1>
            <p>
            Depending on the results of your completed needs analysis, there are a variety of options available to help address which kind of life insurance is best for you and how much is required for your unique needs.
            </p>

            <p>
            Some of the expenses you might need life insurance to cover include:
            </p>

            <div className="text-center hideLg">
              <img
                src="/brochure/4610/images/howMuch_graphic_mbl.svg"
                className="img-responsive"
                style={{ margin: '20px 0 20px 0' }}
                alt=""
              />
            </div>
            <div className="text-center hideSm">
              <img
                src="/brochure/4610/images/howMuch_graphic_dsk.svg"
                style={{ margin: '20px 0 40px 0', width: '750px' }}
                alt=""
              />
            </div>


            <p>
            In determining exactly how much life insurance you might need, remember that the overall purpose of life insurance is to allow your family members to pay the bills and live their lives as planned, despite your absence.
            </p>

            <p>
            No matter your specific needs, WoodmenLife can help protect your family&#8217;s lifestyle and meet your future financial goals.
            </p>



          </div>
        </div>
      </div>



      {hasRep && (
      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Connect"
              >
                <span className="sr-only">Connect</span>
              </a>
            </div>

        </div>
      </div>
      )}



    </section>
  );
};

export default HowMuchSection;
