import React from 'react';

const FinancialSection = () => {
  return (
    <section id="Security">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/securityHero_dsk.jpg"
                alt="WoodmenLife Tower lighting of red, white and blue"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/securityHero_mbl.jpg"
                alt="WoodmenLife Tower lighting of red, white and blue"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Financial Security</h1>
            <p>
              <b>
                <i>Standing Strong Since 1890</i>
              </b>
              <br />
              We&#8217;ve been earning your trust for more than 130 years by taking a long-term approach to maintaining financial stability. Your confidence in us matters, and we&#8217;re dedicated to earning it every day by keeping your best interests in mind with the decisions we make.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto lineSplitterTight"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h2 className="Oswald WLblue">Long-Term Stability</h2>
            <p>
              <b>
                <i>Trusted Strength for More Than 130 Years</i>
              </b>
              <br />
              The economy may change, but our long-term approach to maintaining financial stability won&#8217;t. We make decisions in the best interest of your lifetime security, because your confidence in us matters. By looking ahead, we protect your future and work to ensure that we'll be standing strong when you need us most.
            </p>
          </div>
          
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto lineSplitterTight"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-1 hideSm"></div>
          <div className="col-lg-7 col-xs-12">
            <h2 className="Oswald WLblue">Financial Ratings</h2>
            <p>
              <b>
                <i>Professional Ratings That Make the Grade</i>
              </b>
              <br />
              <img
                src="/brochure/4610/images/amBest.png"
                alt="AM Best Financial Strength Rating A+ graphic"
                width="150"
                align="right"
                style={{ margin: '10px 0 10px 20px' }}
                className="hideLg"
              />
              When it comes to financial health, you don&#8217;t have to take our word for it. AM Best &mdash; an independent rating company nationally recognized for its objective reviews &mdash; awarded WoodmenLife an A+ Superior rating<span className="size12"><sup>2</sup></span>, their second highest out of 13.
              
            </p>
          </div>

          <div className="col-lg-3 col-xs-12 hideSm">
            <div
              className="mx-Auto centerText"
              style={{ margin: '30px 0 10px 0' }}
            >
              <img
                src="/brochure/4610/images/amBest.png"
                alt="AM Best Financial Strength Rating A+ graphic"
                width="180"
              />
            </div>
          </div>
          <div className="col-lg-1 hideSm"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto lineSplitterTight"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h2 className="Oswald WLblue">A History of Steady Growth</h2>
            <p>
              <b>
                <i>Performance You Can Feel Secure In</i>
              </b>
              <br />
              There&#8217;s peace of mind in knowing your financial protection is rooted in an organization with strong, year-over-year financial performance. We&#8217;re dedicated to growing value. That&#8217;s how we&#8217;ve built $11.3 billion in total assets<span className="size12"><sup>3</sup></span>.
            </p>
            <div style={{ marginTop: '10px' }}>&nbsp;</div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-xs-12">
            <div className="centerText size14">
              <b>Assets</b> 10-Year History
              <br />
              <div className="hideLg" style={{ height: '5px' }}>
                &nbsp;
              </div>
              <img
                src="/brochure/4610/images/graph_assets_2023.svg"
                alt="Graph illustrating 10-year history of WoodmenLife Assets"
                className="img-responsive"
              />
              <div style={{ margin: '5px 0 0 0' }}>
                <p className="size10">
                  <b>DOLLARS IN ASSETS (BILLIONS)</b>
                </p>
                <p className="size12">
                Our assets have remained steady over the past several years, and by the end of 2023, our assets stood at $11.3 billion<span className="size10"><sup className="supSm">3</sup></span>.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="centerText size14">
              <b>Insurance in Force</b> 10-Year History
              <br />
            </div>
            <div className="hideLg" style={{ height: '15px' }}>
              &nbsp;
            </div>

            <img
              src="/brochure/4610/images/graph_insurance_2023.svg"
              alt="Graph illustrating 10-year history of WoodmenLife Insurance in Force"
              className="img-responsive"
            />

            <div style={{ margin: '5px 0 50px 0' }}>
              <p className="size10">
                <b>DOLLARS OF FACE AMOUNT IN FORCE (BILLIONS)</b>
              </p>
              <p className="size12">
                Our members own $39.4 billion<sup>*</sup> of life insurance through us.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="centerText size14">
              <b>Surplus</b> 10-Year History
              <br />
              <img
                src="/brochure/4610/images/graph_surplus_2023.svg"
                alt="Graph illustrating 10-year history of WoodmenLife Surplus"
                className="img-responsive"
              />
            </div>
            <div style={{ margin: '5px 0 50px 0' }}>
              <p className="size10">
                <b>DOLLARS OF SURPLUS (BILLIONS)</b>
              </p>
              <p className="size12">
                WoodmenLifes total surplus has remained steady over the past three years at a very strong level of $1.7 billion &mdash; a 17.5% ratio<sup>*</sup>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ marginTop: '20px' }} className="size10">
              *As of Dec. 31, 2023
              <br />
              2. WoodmenLife is rated A+ Superior by AM Best for our financial strength and operating performance in 2023. For the latest Best&#8217;s Credit Rating, access www.ambest.com
              <br />
              3. Liabilities of $9.6 billion; as of Dec. 31, 2023.
            </div>

           
          </div>
        </div>
      </div>


      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Extras"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default FinancialSection;
