import React from 'react';

const CreatingPlanSection = () => {
  return (
    <section id="Plan">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/plan_dsk.jpg"
                alt="Father and daugher looking at a laptop"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/plan_mbl.jpg"
                alt="Father and daugher looking at a laptop"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Creating a Plan for You</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 0px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4610/images/plan1.jpg"
                alt="Asian father and son blowing bubbles"
                width="400"
              />
            </div>

            <p>
            WoodmenLife recognizes that your needs continually change, and that the needs of each individual and family are unique. That's why we offer a variety of products and services that can help everyone, 
            from children to retirees.
            </p>

            <p>
            Look at your situation, then let us be your partner in creating a plan. We can complete a needs analysis and help you take the next step in finding what&#8217;s right for you.
            </p>
            
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Enough"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>
    </section>
  );
};

export default CreatingPlanSection;
