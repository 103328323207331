import React from 'react';

const WhySection = () => {
  return (
    <section id="Why">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/why_dsk.jpg"
                alt="Asian mother and grandmother going for walk with young child"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/why_mbl.jpg"
                alt="Asian mother and grandmother going for walk with young child"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">Why We&#8217;re Here</h1>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '10px 0px 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4610/images/why1.jpg"
                alt="Young African American couple laying on bed with their newborn baby"
                width="400"
              />
            </div>

            <p>
            WoodmenLife Representatives are here to listen, learn about your family and discuss what might be best for your future. 
            We want to understand your current situation, help you identify any possible needs, find solutions to your specific problems, 
            and ensure you have a plan for wherever you are in life. Here are some things to consider:
            </p>

            <p>
                <ul style={{ listStyleType: 'none' }}>
                    <li style={{ padding: '0 0 10px 0' }}><b>If something happens to me, will the people I care about the most be taken care of?</b></li>
                    <li style={{ padding: '0 0 10px 0'}}><b>Am I saving enough money for the future?</b></li>                  
                    <li style={{ padding: '0 0 10px 0'}}><b>Am I prepared for retirement?</b></li>
                    <li style={{ padding: '0 0 10px 0'}}><b>How can I maximize the legacy I will leave behind?</b></li>
                </ul>

            </p>

            <p>
            Every family has a story. Now that we&#8217;ve heard a little about yours, we&#8217;ll tell you ours &mdash; and together we can find answers to some of these questions.
            </p>

           


          </div>
        </div>
      </div>
    
      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Story"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>

    </section>
  );
};

export default WhySection;
