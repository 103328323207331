import React from 'react';

const StorySection = () => {
  return (
    <section id="Story">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/storyHero_dsk.jpg"
                alt="Adult woman hugging her senior mom"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/storyHero_mbl.jpg"
                alt="Adult woman hugging her senior mom"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <span className="headline Oswald WLblue">
              The WoodmenLife<span style={{ fontSize: '24px' }}><sup>&reg;</sup></span> Story
            </span>

            <p>
            WoodmenLife was founded with a simple, two-part purpose: to protect America&#8217;s families from financial hardship and to provide opportunities to help others in the community.
            </p>
            <p>
            We&#8217;ve been helping to protect the financial future of families like yours, making a difference in hometowns across America and honoring our country since 1890. As a not-for-profit life insurance company, we put money back into the community. We&#8217;re here when you need us most.
            </p>
            <p>
            As you make plans for your financial future, we invite you to become part of WoodmenLife, a strong company that shares your values.
            </p>

            <div
              style={{
                width: '100%',
                margin: '0px 0px 20px 0px',
                backgroundColor: '#ffffff',
              }}
              className="hideLg"
            >
              <img
                src="/brochure/4610/images/story1.jpg"
                alt="Home with a white picket fence displaying American Flags"
                className="img-responsive"
              />
            </div>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '20px 0px 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4610/images/story1.jpg"
                alt="Home with a white picket fence displaying American Flags"
                width="400"
              />
            </div>

            <h2 className="Oswald WLblue">Our Mission</h2>
            <p>
            Uniting hardworking Americans to secure their financial future while strengthening our communities and country.
            </p>

            <h2 className="Oswald WLblue">Our Values</h2>
            <p>
              At WoodmenLife, we are committed to serving our members<span className="size12"><sup>1</sup></span>, their communities and our country. As a not-for-profit insurance company, we are proud to reinvest what would be profits into local communities to benefit those in need. We serve our members with honesty and integrity, and we have always been guided by good. Our values include:
            </p>

            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xs-6">
                  <b className="Oswald">&bull;</b>&nbsp; Passion for Members
                  <br />
                  <b className="Oswald">&bull;</b>&nbsp; Service to Others
                  <br />
                  <b className="Oswald">&bull;</b>&nbsp; Inclusion
                  <br />
                </div>

                <div className="col-lg-4 col-xs-6">
                  <b className="Oswald">&bull;</b>&nbsp; Collaborative Spirit
                  <br />
                  <b className="Oswald">&bull;</b>&nbsp; Open-mindedness
                  <br />
                  
                </div>

                <div className="col-lg-4 hideSm"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ marginTop: '40px' }} className="size10">
              1. An individual becomes a member by joining our shared commitment
              to family, community and country, and by purchasing a WoodmenLife
              product.
            </div>

            
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Security"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


      
    </section>
  );
};

export default StorySection;
