import React from 'react';

const HeroSection = () => {
  return (
    <div className="heroBkg_4610">
      <header className="white">
        <div className="container text-center">
          <div>
            <img
              src="/brochure/4610/images/flag.png"
              style={{ marginBottom: '10px' }}
              className="flag"
              alt="flag"
            />
            <br />
            <div className="h2hero white hideSm">
              A Life Insurance Company that Gives Back
            </div>
            <div className="h2hero white hideLg">
              A Life Insurance Company
              <br />
              that Gives Back
            </div>

            <div
              className="arrow bounce whiteArrow"
              style={{ marginTop: '175px' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Why"
              >
                &nbsp;
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="top-left darkBkg white size10">
        <b>Form 4610D R-6/24</b>
      </div>
    </div>
  );
};

export default HeroSection;
