import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import CreatingPlanSection from '../../../components/Brochure/4610/CreatingPlanSection';
import ExtrasSection from '../../../components/Brochure/4610/ExtrasSection';
import FinancialSection from '../../../components/Brochure/4610/FinancialSection';
import HeroSection from '../../../components/Brochure/4610/HeroSection';
import HowMuchSection from '../../../components/Brochure/4610/HowMuchSection';
import HowWorkSection from '../../../components/Brochure/4610/HowWorkSection';
import NavSection from '../../../components/Brochure/4610/NavSection';
import StorySection from '../../../components/Brochure/4610/StorySection';
import WhatWeOfferSection from '../../../components/Brochure/4610/WhatWeOfferSection';
import WhySection from '../../../components/Brochure/4610/WhySection';
import { BrochureFooter } from '../../../components/Brochure/BrochureFooter';
import RepDetails from '../../../components/Brochure/RepDetails';
import { useRepSearch } from '../../../hooks/useRepSearch';
import useScript from '../../../hooks/useScript';

const Brochure4610 = () => {
  const [repLookupComplete, repData] = useRepSearch();

  useScript(withPrefix('/brochure/js/jquery.min.js'));
  useScript(withPrefix('/brochure/js/bootstrap.bundle.min.js'));
  useScript(withPrefix('/brochure/js/jquery.easing.min.js'));
  useScript(withPrefix('/js/scrolling-nav.js'), !repLookupComplete);
  useScript(withPrefix('/js/zoom.js'));

  return (
    <>
      <Helmet>
        <html className="brochure" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>
          Form 4610D R-6/24 - WoodmenLife - Life Insurance That Gives Back
        </title>

        <link href="/brochure/css/bootstrap.min.css" rel="stylesheet" />

        <link href="/brochure/css/styles.css?rev=20210527" rel="stylesheet" />
        <link href="/brochure/css/overrides.css" rel="stylesheet" />
        <script src="https://kit.fontawesome.com/8f3009470a.js"></script>

        <link
          href="https://fonts.googleapis.com/css?family=Oswald:700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,800,800i"
          rel="stylesheet"
        />
      </Helmet>
      <body id="page-top">
        <NavSection hasRep={Boolean(repData)} />
        <HeroSection />
        <WhySection />
        <StorySection />
        <FinancialSection />
        <ExtrasSection />
        <HowWorkSection />
        <WhatWeOfferSection />
        <CreatingPlanSection />
        <HowMuchSection hasRep={Boolean(repData)} />
        {repData && <RepDetails producer={repData} />}
        <BrochureFooter wlLink={'/'} />
      </body>
    </>
  );
};

export default Brochure4610;
