import React from 'react';

const ExtrasSection = () => {
  return (
    <section id="Extras">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/4610/images/extrasHero_dsk.jpg"
                alt="Firefighers putting hands together symbolizing teamwork"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/4610/images/extrasHero_mbl.jpg"
                alt="Firefighers putting hands together symbolizing teamwork"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="Oswald WLblue">WoodmenLife Extras</h1>
            <p>
              <b className="size18">
                <i>Extras to Help Your Family Get More Out of Life Now</i>
              </b>
              <br /> 
              As a member of the WoodmenLife family, you have access to a wide range of valuable extras<span className="size12"><sup>4</sup></span>. From help with paying for education to financial assistance following a natural disaster, these extras are designed to help your family right now. There&#8217;s no extra cost nor obligation to use them. It&#8217;s just one way our family is committed to helping yours wherever you may be in life.
            </p>
            <h2 className="Oswald navy" style={{ margin: '0 0 10px 0'}}>Support for Your Family</h2>

            <div
              style={{
                width: '100%',
                margin: '0px 0px 20px 0px',
                backgroundColor: '#ffffff',
              }}
              className="hideLg"
            >
              <img
                src="/brochure/4610/images/extras_student.jpg"
                alt="African American college student studying with classmates"
                className="img-responsive"
              />
            </div>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '20px 0px 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4610/images/extras_student.jpg"
                alt="African American college student studying with classmates"
                width="400"
              />
            </div>


            <p>
              <b className="Oswald WLblue size20">Help Paying for College</b><br/>
              <i className="size18">Scholarship Opportunities<span className="size12"><sup>4</sup></span> for Your Children</i><br/>
              Since 2017, WoodmenLife has awarded scholarships worth more than $3 million to high school graduates for continuing education opportunities through the WoodmenLife Focus Forward Scholarship<sup>&reg;</sup>.
            </p>

            <p>
              <b className="Oswald WLblue size20">Repaying Student Loans</b><br/>
              <i className="size18">Help to Lighten the Load of Your Student Loans</i><br/>
              We can help you get closer to financial security through the WoodmenLife Student Loan Relief program, which offers the opportunity to apply for a $1,000<span className="size12"><sup>4</sup></span> award to reduce the burden of student loan debt.
            </p>

            <p>
              <b className="Oswald WLblue size20">Self-Service Legal Documentation</b><br/>
              <i className="size18">Easy-to-Use Online Templates</i><br/>
              Advance planning makes change &mdash; even the difficult change involved in illness or death &mdash; easier to cope with. With LawAssure<sup>&trade;</sup>, WoodmenLife members can create customized wills, powers of attorney and healthcare directives with free, easy-to-use online templates<span className="size12"><sup>5</sup></span>.
            </p>

            <p>
              <b className="Oswald WLblue size20">Rebuilding After a Natural Disaster</b><br/>
              <i className="size18">Help to Get You Back Home</i><br/>
              We can&#8217;t stop natural disasters, but we can help members of the WoodmenLife family who experience one by providing financial assistance of up to $1,000<span className="size12"><sup>4</sup></span>.
            </p>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '20px 0 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4610/images/extras_shopping.jpg"
                alt="Mother carrying baby and groceries to her car"
                width="400"
              />
            </div>

            <p>
              <b className="Oswald WLblue size20">Caring for Your Children</b><br/>
              <i className="size18">Being There for Your Children When You Can&#8217;t</i><br/>
              Should your children be orphaned, WoodmenLife will provide each child $1,000<span className="size12"><sup>4</sup></span> a month to pay for their care, until they reach the age of 19, through the Family Legacy extra. If your child is a full-time post-secondary student, the payments will continue until age 22, as long as certain requirements are met.
            </p>
            
            <div
              style={{
                width: '100%',
                margin: '0px 0px 20px 0px',
                backgroundColor: '#ffffff',
              }}
              className="hideLg"
            >
              <img
                src="/brochure/4610/images/extras_shopping.jpg"
                alt="Mother carrying baby and groceries to her car"
                className="img-responsive"
              />
            </div>

            

            <p>
              <b className="Oswald WLblue size20">Saving Money</b><br/>
              <i className="size18">Everyday Shopping Discounts You Can Use Now</i><br/>
              Our discount program &mdash; Life&#8217;s Perks<sup>&reg;</sup> &mdash; gives you everyday savings<span className="size12"><sup>6</sup></span> at more than 30,000 local and national retailers, such as Verizon, Sam&#8217;s Club<sup>&reg;</sup> and more. Plus, redeem local coupons and online deals on the go via the PerkSpot Mobile App.
            </p>

            <h2 className="Oswald navy" style={{ margin: '40px 0 10px 0'}}>Support for Your Community</h2>
            
            <p>
              <b className="Oswald WLblue size20">Volunteer Opportunities</b><br/>
              <i className="size18">Make a Difference</i><br/>
              You have an open invitation to support your community through local volunteer activities. Members of the WoodmenLife family plan and carry out thousands of service projects each year.
            </p>

            <p>
              <div
                style={{
                  float: 'right',
                  width: '150px',
                  margin: '0px 0 10px 30px',
                  backgroundColor: '#ffffff',
                }}
                className="hideSm"
              >
                <img
                  src="/brochure/4610/images/NCF.png"
                  alt="National"
                  width="150"
                />
              </div>
              
              <b className="Oswald WLblue size20">National Community Focus</b><br/>
              <div
                style={{
                  float: 'right',
                  width: '110px',
                  margin: '0px 0 20px 20px',
                  backgroundColor: '#ffffff',
                }}
                className="hideLg"
              >
                <img
                  src="/brochure/4610/images/NCF.png"
                  alt="National"
                  width="110"
                />
              </div>
              <i className="size18">Everyday Shopping Discounts You Can Use Now</i><br/>
              WoodmenLife helps support our national fight against hunger. Since 2015, we&#8217;ve raised $3.6 million to provide food to those in need and collected 1.4 million pounds of food.

            </p>
            
            <h2 className="Oswald navy" style={{ margin: '40px 0 10px 0'}}>Support for Our Country</h2>
            

            <div
              style={{
                width: '100%',
                margin: '0px 0px 20px 0px',
                backgroundColor: '#ffffff',
              }}
              className="hideLg"
            >
              <img
                src="/brochure/4610/images/extras_flag.jpg"
                alt="WoodmenLife American flag presentation at a high school football game"
                className="img-responsive"
              />
            </div>

            <div
              style={{
                float: 'right',
                width: '400px',
                margin: '20px 0 40px 40px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/4610/images/extras_flag.jpg"
                alt="WoodmenLife American flag presentation at a high school football game"
                width="400"
              />
            </div>

            <p>
              <b className="Oswald WLblue size20">Patriotism</b><br/>
              <i className="size18">Celebrate Your American Pride</i><br/>
              We value our American freedoms, just like you. That&#8217;s why we&#8217;ve presented more than 3.5 million flags to nonprofit organizations, schools and communities nationwide.
            </p>

            <p>
              <b className="Oswald WLblue size20">Honoring Heroes</b><br/>
              <i className="size18">A Tribute to Families of Fallen First Responders</i><br/>
              True heroes deserve more. That&#8217;s why we pay $25,000<span className="size12"><sup>7</sup></span> to the family of every First Responder member who dies in the line of duty.
            </p>

            <p>
              <b className="Oswald WLblue size20">Celebrating Red, White &amp; Blue<span style={{ fontSize: '14px' }}><sup>&reg;</sup></span></b><br/>
              <i className="size18">Proudly Displaying Our Flag</i><br/>
              WoodmenLife members can celebrate our shared commitment to patriotism by displaying an oversized American flag at their local event or activity.
            </p>


          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ marginTop: '30px' }} className="size10">
              4. WoodmenLife Extras are available to members. An individual becomes a member by joining our shared commitment to family, community and country, and by purchasing a WoodmenLife product. These extras are not contractual, are subject to change and have specific eligibility requirements, such as length of membership, number of qualifying members in household and/or qualifying event.
              <br />
              5. WoodmenLife has entered into a sponsored marketing relationship with Epoq, Inc. (Epoq) to offer document preparation services to WoodmenLife members. Epoq is an independent service provider. WoodmenLife is not affiliated with Epoq and does not administer these document preparation services. WoodmenLife does not provide, is not responsible for, does not assume liability for, and does not guarantee the accuracy, adequacy or results of any service or documents provided by Epoq. WoodmenLife, its employees and representatives are not authorized to give legal advice. Not all services are available in all states.
              <br />
              6. WoodmenLife has entered into sponsored marketing relationships with companies that agree to offer discounts to WoodmenLife members. WoodmenLife is not affiliated with these companies and does not administer these discounts for products or services.
              <br />
              7. $25,000 benefit applies to officially registered non-military First Responders. WoodmenLife Extras are not contractual, are subject to change and have specific eligibility requirements.
            </div>

           
          </div>
        </div>
      </div>


      <div className="container text-center">
        <div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Work"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>

    </section>
  );
};

export default ExtrasSection;
